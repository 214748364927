<template>
  <div class="table-responsive" style="overflow-x: scroll; overflow: visible">
    <div class="form-row">
      <div class="col-lg-12">
        <base-button
        class="float-left"
        @click="$emit('cancelEdit')"
        type="danger"
        icon
        size="sm"
        >
        <i class="fas fa-edit mr-2"></i>

        <span class="btn-inner--text">Cancel edit</span>
      </base-button>
      <base-button
      class="float-right mx-1"
      type="primary"
      icon
      size="sm"
      @click="
      Service.addLineup(list, {
        terminal_id: terminal.id,
        porto_id: terminal.porto_id,
        carga_tipo_id: carga.id,
      })
      "
      >
      <i class="fas fa-plus mr-2"></i>
      <span class="btn-inner--text">New vessel</span>
    </base-button>
    <base-button
    class="float-right mx-1"
    type="primary"
    icon
    size="sm"
    @click="
    Service.addLineup(list, {
      terminal_id: terminal.id,
      porto_id: terminal.porto_id,
      is_observacao: 1,
    })
    "
    >
    <i class="fas fa-plus mr-2"></i>
    <span class="btn-inner--text">New note</span>
  </base-button>
  <base-button
  class="float-right mx-1"
  type="primary"
  icon
  size="sm"
  @click="lockYear = lockYear ? false : true"
  >
  <i
  class="fas mr-2"
  :class="{ 'fa-lock': lockYear, 'fa-lock-open': !lockYear }"
  ></i>

  <span class="btn-inner--text">Year</span>
</base-button>
<base-button
class="float-right mx-1"
type="primary"
icon
size="sm"
@click="lockHour = lockHour ? false : true"
>
<i
class="fas mr-2"
:class="{ 'fa-lock': lockHour, 'fa-lock-open': !lockHour }"
></i>

<span class="btn-inner--text">Hour</span>
</base-button>

<base-button
class="float-right"
type="primary"
icon
size="sm"
@click="saveList"
>
<i class="fas fa-save mr-2"></i>
<span class="btn-inner--text">Save lines</span>
</base-button>
</div>
</div>

<table
class="table align-items-center table-flush tablesorter tabela-form mt-2"
tbody-classes="list"
>
<thead class="thead-light">
  <tr v-if="orderList.length">
    <th>Vessel</th>
    <th>Status</th>   
    <th v-if="carga.name == 'TANKERS'">Berth</th>
    <th>ETA</th>
    <th>ETB</th>
    <th>ETS</th>
    <th>Last port</th>
    <th>Next port</th>
    <th>OPS</th>
    <th>Cargo</th>
    <th v-if="carga.name != 'TANKERS' && carga.name != 'SUGAR'">QTY</th>
    <th v-if="carga.name == 'SUGAR'" class="text-center">BULK</th>
    <th v-if="carga.name == 'SUGAR'" class="text-center">BAGGED</th>
    <th v-if="carga.name == 'TANKERS'">QTY DISCH</th>
    <th v-if="carga.name == 'TANKERS'">QTY LOADED</th>
    <th>Charterer</th>
    <th></th>
  </tr>
</thead>

<tbody class="list">
  <template v-for="(row, index) in orderList">
    <tr @click="currentRow = row">

      <td>
        <span v-if="!row.is_observacao">
          <multiple-select
          :form-group="false"
          placeholder="Select the vessels"
          :options="$parent.navios"
          optionsValue="name"
          :create="true"
          :edit="true"
          @create="
          () => {
            rowIndex = row;
            openModalCreate('navio');
          }
          "
          @edit="
          () => {
            rowIndex = row;
            openModalCreate('navio', null, row.navio_id);
          }
          "
          v-model="row.navio_id"
          :errors="errors[`list.${index}.navio_id`]"
          />
          <b v-if="row.call_number">
            *{{ row.call_number + (row.call_number > 1 ? "ND" : "ST") }}
            CALL
          </b>
        </span>
        <span v-else>
          <base-input
          type="text"
          v-model="row.observacao"
          :errors="errors[`list.${index}.observacao`]"
          />
        </span>
      </td>
      <td>
        <span v-if="!row.is_observacao">
          <multiple-select
          :form-group="false"
          placeholder="Select the status"
          :options="$parent.status"
          optionsValue="status"

          v-model="row.lineup_status_id"
          :errors="errors[`list.${index}.lineup_status_id`]"
          />

        </span>

      </td>
      <td v-if="carga.name == 'TANKERS'">
        <span v-if="!row.is_observacao">
          <multiple-select
          :form-group="false"
          :errors="errors[`list.${index}.berco_id`]"
          placeholder="select the berth"
          :options="terminal.bercos"
          optionsValue="name"
          v-model="row.berco_id"
          />
        </span>
        <!-- {{ row.berco ? row.berco.name : "" }} -->
      </td>
      <td>
        <span :ref="index + '_eta'">
          <base-input-date
          v-if="!row.is_observacao"
          :errors="errors[`list.${index}.eta`]"
          placeholder="TBI"
          :lockedYear="lockYear"
          :lockedHour="lockHour"
          v-model="row.eta"
          />
        </span>
      </td>
      <td>
        <span :ref="index + '_etb'">
          <base-input-date
          :errors="errors[`list.${index}.etb`]"
          placeholder="TBI"
          :lockedYear="lockYear"
          v-model="row.etb"
          :handleTab="tabEtb"
          />
        </span>
      </td>
      <td>
        <span :ref="index + '_ets'">
          <base-input-date
          :class="currentRow == row ? 'current-row-ets' : ''"
          :errors="errors[`list.${index}.ets`]"
          placeholder="TBI"
          :lockedYear="lockYear"
          :lockedHour="lockHour"
          :handleTab="
          () => {
            tabEts(index);
          }
          "
          v-model="row.ets"
          />
        </span>
      </td>
      <td>
        <span v-if="!row.is_observacao">
          <multiple-select
          :form-group="false"
          placeholder="last port"
          :errors="errors[`list.${index}.import_porto_id`]"
          :options="$parent.lineupPortos"
          optionsValue="port_name"
          v-model="row.import_porto_id"
          :create="true"
          :edit="true"
          @create="
          () => {
            rowIndex = row;
            openModalCreate('lineup_porto', 'import_porto_id');
          }
          "
          @edit="
          () => {
            rowIndex = row;
            openModalCreate(
              'lineup_porto',
              'import_porto_id',
              row.import_porto_id
              );
          }
          "
          />
        </span>
      </td>
      <td>
        <span v-if="!row.is_observacao">
          <multiple-select
          :form-group="false"
          placeholder="next port"
          :options="$parent.lineupPortos"
          :errors="errors[`list.${index}.export_porto_id`]"
          optionsValue="port_name"
          v-model="row.export_porto_id"
          :create="true"
          :edit="true"
          @create="
          () => {
            rowIndex = row;
            openModalCreate('lineup_porto', 'export_porto_id');
          }
          "
          @edit="
          () => {
            rowIndex = row;
            openModalCreate(
              'lineup_porto',
              'export_porto_id',
              row.export_porto_id
              );
          }
          "
          />
        </span>
      </td>
      <td>
        <span v-if="!row.is_observacao">
          <multiple-select
          :form-group="false"
          :errors="errors[`list.${index}.ops_id`]"
          placeholder="select the ops"
          :options="ops"
          optionsValue="name"
          v-model="row.ops_id"
          />
        </span>
      </td>
      <td>
        <span v-if="!row.is_observacao">
          <multiple-select
          :form-group="false"
          placeholder="cargo"
          :options="getCargas(row)"
          @input="setCargaTipo(row)"
          optionsValue="name"
          v-model="row.carga_id"
          :errors="errors[`list.${index}.carga_id`]"
          :create="true"
          :edit="true"
          @create="
          () => {
            rowIndex = row;
            openModalCreate('carga', null, null, {
              carga_tipo_id: row.carga_tipo_id,
            });
          }
          "
          @edit="
          () => {
            rowIndex = row;
            openModalCreate('carga', null, row.carga_id, {
              carga_tipo_id: row.carga_tipo_id,
            });
          }
          "
          />
        </span>
      </td>

      <td v-if="carga.name != 'TANKERS'  && carga.name != 'SUGAR'" class="qty">
        <money
        class="form-control"
        v-model="row.qty"
        v-bind="$root.qtd"
        v-if="!row.is_observacao"
        ></money>
        <div
        class="text-danger invalid-feedback"
        style="display: block"
        v-for="erro in errors[`list.${index}.qty`]"
        v-if="errors[`list.${index}.qty`]"
        >
        {{ erro }}
      </div>
    </td>
    <td v-if="carga.name == 'SUGAR'" class="qty">
        <money
        class="form-control"
        v-model="row.qty_bulk"
        v-bind="$root.qtd"
        :disabled="row.qty_bagged > 0"
        v-if="!row.is_observacao"
        ></money>
        <div
        class="text-danger invalid-feedback"
        style="display: block"
        v-for="erro in errors[`list.${index}.qty_bulk`]"
        v-if="errors[`list.${index}.qty_bulk`]"
        >
        {{ erro }}
      </div>
    </td>
    <td v-if="carga.name == 'SUGAR'" class="qty">
        <money
        class="form-control"
        v-model="row.qty_bagged"
        :disabled="row.qty_bulk > 0"
        v-bind="$root.qtd"
        v-if="!row.is_observacao"
        ></money>
        <div
        class="text-danger invalid-feedback"
        style="display: block"
        v-for="erro in errors[`list.${index}.qty_bagged`]"
        v-if="errors[`list.${index}.qty_bagged`]"
        >
        {{ erro }}
      </div>
    </td>

    <td v-if="carga.name == 'TANKERS'">
      <span
      v-if="
      row.ops_id == 3 || (row.ops_id == 1 && row.import_porto_id)
      "
      >
      <money
      class="form-control"
      v-model="row.qty"
      v-bind="$root.qtd"
      v-if="!row.is_observacao"
      ></money>
      <div
      class="text-danger invalid-feedback"
      style="display: block"
      v-for="erro in errors[`list.${index}.qty`]"
      v-if="errors[`list.${index}.qty`]"
      >
      {{ erro }}
    </div>
  </span>
</td>
<td v-if="carga.name == 'TANKERS'">
  <span
  v-if="
  row.ops_id == 2 || (row.ops_id == 1 && row.export_porto_id)
  "
  >
  <money
  class="form-control"
  v-model="row.qty"
  v-bind="$root.qtd"
  v-if="!row.is_observacao"
  ></money>
  <div
  class="text-danger invalid-feedback"
  style="display: block"
  v-for="erro in errors[`list.${index}.qty`]"
  v-if="errors[`list.${index}.qty`]"
  >
  {{ erro }}
</div>
</span>
</td>

<td>
  <multiple-select
  :errors="errors[`list.${index}.afretador_id`]"
  :form-group="false"
  placeholder="Charterer"
  :options="$parent.afretadores"
  optionsValue="name"
  v-model="row.afretador_id"
  v-if="!row.is_observacao"
  :create="true"
  :edit="true"
  @create="
  () => {
    rowIndex = row;
    openModalCreate('afretador');
  }
  "
  @edit="
  () => {
    rowIndex = row;
    openModalCreate('afretador', null, row.afretador_id);
  }
  "
  />
</td>
<td>
  <!-- btn ações row -->
  <div class="btn-group">
    <button
    class="btn btn-sm redondo btn-danger"
    @click="deleteLine(row)"
    >
    <i class="fas fa-trash"></i>
  </button>

  <button
  class="ml-1 btn btn-sm redondo btn-primary"
  @click="addLineupCarga(row)"
  v-if="!row.is_observacao"
  >
  <i class="fas fa-plus"></i>
</button>
</div>
<!-- /btn ações row -->
</td>
</tr>

<!-- cargas adicionais -->
<tr v-for="(cargaRow, indexCarga) in row.lineups_cargas">
  <td></td>
  <td></td>
  <td v-if="carga.name == 'TANKERS'"></td>
  <td></td>
  <td></td>
  <td></td>

  <td>
    <multiple-select
    :form-group="false"
    placeholder="last port"
    :errors="
    errors[
    `list.${index}.lineups_cargas.${indexCarga}.import_porto_id`
    ]
    "
    :options="$parent.lineupPortos"
    optionsValue="port_name"
    v-model="cargaRow.import_porto_id"
    :create="true"
    :edit="true"
    @create="
    () => {
      rowIndex = cargaRow;
      openModalCreate('lineup_porto', 'import_porto_id');
    }
    "
    @edit="
    () => {
      rowIndex = cargaRow;
      openModalCreate(
        'lineup_porto',
        'import_porto_id',
        cargaRow.import_porto_id
        );
    }
    "
    />
  </td>
  <td>
    <multiple-select
    :form-group="false"
    placeholder="next port"
    :options="$parent.lineupPortos"
    :errors="
    errors[
    `list.${index}.lineups_cargas.${indexCarga}.export_porto_id`
    ]
    "
    optionsValue="port_name"
    v-model="cargaRow.export_porto_id"
    :create="true"
    :edit="true"
    @create="
    () => {
      rowIndex = cargaRow;
      openModalCreate('lineup_porto', 'export_porto_id');
    }
    "
    @edit="
    () => {
      rowIndex = cargaRow;
      openModalCreate(
        'lineup_porto',
        'export_porto_id',
        cargaRow.export_porto_id
        );
    }
    "
    />
  </td>
  <td>
    <multiple-select
    :form-group="false"
    :errors="
    errors[`list.${index}.lineups_cargas.${indexCarga}.ops_id`]
    "
    placeholder="select the ops"
    :options="ops"
    optionsValue="name"
    v-model="cargaRow.ops_id"
    />
  </td>

  <td>
    <multiple-select
    :form-group="false"
    placeholder="cargo"
    :options="getCargas(cargaRow)"
    @input="setCargaTipo(cargaRow)"
    optionsValue="name"
    v-model="cargaRow.carga_id"
    :errors="
    errors[`list.${index}.lineups_cargas.${indexCarga}.carga_id`]
    "
    :create="true"
    :edit="true"
    @create="
    () => {
      rowIndex = cargaRow;
      openModalCreate('carga', null, null, {
        carga_tipo_id: row.carga_tipo_id,
      });
    }
    "
    @edit="
    () => {
      rowIndex = cargaRow;
      openModalCreate('carga', null, cargaRow.carga_id, {
        carga_tipo_id: cargaRow.carga_tipo_id,
      });
    }
    "
    />
  </td>
  <td v-if="carga.name != 'TANKERS' && carga.name != 'SUGAR'">
    <money
    class="form-control"
    v-model="cargaRow.qty"
    v-bind="$root.qtd"
    ></money>
    <div
    class="text-danger invalid-feedback"
    style="display: block"
    v-for="erro in errors[
    `list.${index}.lineups_cargas.${indexCarga}.qty`
    ]"
    v-if="errors[`list.${index}.lineups_cargas.${indexCarga}.qty`]"
    >
    {{ erro }}
  </div>
</td>
  <td v-if="carga.name == 'SUGAR'" class="qty">
        <money
        class="form-control"
        v-model="cargaRow.qty_bulk"
        v-bind="$root.qtd"
        :disabled="cargaRow.qty_bagged > 0"
        
        ></money>
        <div
        class="text-danger invalid-feedback"
        style="display: block"
        v-for="erro in errors[`list.${index}.lineups_cargas.${indexCarga}.qty_bulk`]"
        v-if="errors[`list.${index}.lineups_cargas.${indexCarga}.qty_bulk`]"
        >
        {{ erro }}
      </div>
    </td>
  <td v-if="carga.name == 'SUGAR'" class="qty">
        <money
        class="form-control"
        v-model="cargaRow.qty_bagged"
        v-bind="$root.qtd"
        :disabled="cargaRow.qty_bulk > 0"
        
        ></money>
        <div
        class="text-danger invalid-feedback"
        style="display: block"
         v-for="erro in errors[`list.${index}.lineups_cargas.${indexCarga}.qty_bagged`]"
        v-if="errors[`list.${index}.lineups_cargas.${indexCarga}.qty_bagged`]"
        >
        {{ erro }}
      </div>
    </td>
  
<td v-if="carga.name == 'TANKERS'">
  <span
  v-if="
  (cargaRow.ops_id == 3 ||
    (cargaRow.ops_id == 1 && cargaRow.import_porto_id)) &&
  cargaRow.qty > 0
  "
  >
  <money
  class="form-control"
  v-model="cargaRow.qty"
  v-bind="$root.qtd"
  ></money>
  <div
  class="text-danger invalid-feedback"
  style="display: block"
  v-for="erro in errors[
  `list.${index}.lineups_cargas.${indexCarga}.qty`
  ]"
  v-if="
  errors[`list.${index}.lineups_cargas.${indexCarga}.qty`]
  "
  >
  {{ erro }}
</div>
</span>
</td>
<td v-if="carga.name == 'TANKERS'">
  <span
  v-if="
  (cargaRow.ops_id == 2 ||
    (cargaRow.ops_id == 1 && cargaRow.export_porto_id)) &&
  cargaRow.qty > 0
  "
  >
  <money
  class="form-control"
  v-model="cargaRow.qty"
  v-bind="$root.qtd"
  ></money>
  <div
  class="text-danger invalid-feedback"
  style="display: block"
  v-for="erro in errors[
  `list.${index}.lineups_cargas.${indexCarga}.qty`
  ]"
  v-if="
  errors[`list.${index}.lineups_cargas.${indexCarga}.qty`]
  "
  >
  {{ erro }}
</div>
</span>
</td>
<td></td>

<td>
  <div class="btn-group">
    <button
    class="btn btn-sm btn-danger redondo"
    @click="removeLineupCarga(row, indexCarga)"
    >
    <i class="fas fa-trash"></i>
  </button>
</div>
</td>
</tr>
</template>
<!-- /cargas adicionais -->

<tr v-if="!list.length && !loading">
  <td colspan="100" style="text-align: center">Nenhuma lineup</td>
</tr>
<tr v-if="loading">
  <td colspan="100" style="text-align: center">
    <i
    class="fa fa-spinner fa-spin"
    v-if="loading"
    style="font-size: 20px"
    ></i>
  </td>
</tr>
</tbody>
</table>
<modal
:show="modal.create.show"
@close="
() => {
  modal.create.show = false;
}
"
>
<template v-slot:header>
  <span v-if="modal.create.id">Edit</span> <span v-else>Create new</span>
</template>
<template>
  <generic-form
  v-if="modal.create.show"
  :service="modal.create.service"
  :type="modal.create.type"
  :id="modal.create.id"
  :params="modal.create.params"
  @success="handleModalForm"
  />
</template>
</modal>
</div>
</template>
<script>
  import Service from "@/services/Lineups";
  import LineupsPortosService from "@/services/LineupsPortos";
  import PortosService from "@/services/Portos";
  import NaviosService from "@/services/Navios";
  import TrabalhadoresService from "@/services/Trabalhadores";
  import AgenciasService from "@/services/Agencias";
  import AfretadoresService from "@/services/Afretadores";
  import CargasTiposService from "@/services/CargasTipos";
  import CargasService from "@/services/Cargas";
  import GenericForm from "./../Generic/Form.vue";
  import { Money } from "v-money";
  export default {
    components: {
      Money,
      "generic-form": GenericForm,
    },
    props: {
      lineups: {
        default: () => [],
      },
      carga_tipo_id: {
        default: 0,
      },
      carga: {
        default: () => {},
      },
      cargas: {
        default: () => [],
      },
      terminal: {
        default: () => {},
      },
      ops: {
        default: [],
      },
    },
    data() {
      return {
        Service,
        currentRow: null,
        rowIndex: {},
        loading: false,
        lockYear: true,
        lockHour: true,
        search: {
          terminal_id: null,
          carga_tipo_id: null,
        },
        errors: {},
        orderBy: {
          campo: "etb",
          ordem: "asc",
        },
        modal: {
          create: {
            show: false,
            service: {},
            type: null,
            params: {},
            model: null,
            index: null,
            id: null,
          },
        },
        deletedLines: [],
        list: [
        // {
        //   name: "Admin",
        //   email: "admin@jsonapi.com",
        //   created_at: "2020-01-01",
        // },
        ],
      };
    },
    created() {
      if (this.lineups) {
        this.list = [...this.lineups];
      } else {
        this.listar();
      }
    },
    computed: {
      orderList() {
        return this.list.sort(
          (a, b) =>
          new Date(a.etb || "9999-12-31") - new Date(b.etb || "9999-12-31")
          );
      },
    },
    watch: {
    // lineups: {
    //   deep: true,
    //   handler() {
    //     this.list = [...this.lineups];
    //   },
    // },
    //  list(){

    //     this.list = this.list.sort(
    //             (a, b) =>
    //               new Date(a.etb || '9999-12-31') -
    //               new Date(b.etb || '9999-12-31')
    //           );

    // },
    search: {
      deep: true,
      handler() {
        this.listar();
      },
    },
    orderBy: {
      deep: true,
      handler() {
        this.pagination.page = 1;
        this.listar();
      },
    },
  },
  methods: {
    removeLineupCarga(row, index) {
      row.lineups_cargas.splice(index, 1);
    },
    addLineupCarga(row) {
      this.Service.addCarga(row, {
        carga_tipo_id: row.carga_tipo_id,
      });
    },
    deleteLine(row) {
      if (row.id) {
        this.deletedLines.push(row);
      }
      let index = this.list.indexOf(row);
      this.list.splice(index, 1);
    },
    async saveList() {
      this.errors = {};
      this.loading = true;
      Service.saveList(this.orderList, this.terminal.id, this.deletedLines)
      .then((response) => {
        this.$emit("cancelEdit");
        this.deletedLines = [];
        this.loading = false;
        this.$notify({
          type: "success",
          message: "Lineup saved successfully!",
          horizontalAlign: "center",
        });
      })
      .catch((err) => {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: "Please check all lines.",
          horizontalAlign: "center",
        });
        this.errors = err.response.data.errors;
      });
    },
    tabEts(index) {
      if (this.$refs[(this.list.length > index + 1 ? index + 1 : 0) + "_eta"]) {
        this.$refs[(this.list.length > index + 1 ? index + 1 : 0) + "_eta"][0]
        .querySelector("input")
        .focus();
      }
    },
    tabEtb() {
      new Promise((resolve) => setTimeout(resolve, 0)).then(() => {
        document.querySelector(".current-row-ets input").focus();
        this.currentRow = null;
      });
    },
    openModalCreate(type, model, id, params) {
      this.modal.create.id = id;
      switch (type) {
        case "agencia":
        this.modal.create.service = AgenciasService;
        this.modal.create.params = {};

        break;

        case "navio":
        this.modal.create.service = NaviosService;
        this.modal.create.params = {};
        this.modal.create.id = id;
        break;

        case "afretador":
        this.modal.create.service = AfretadoresService;
        this.modal.create.params = {};

        break;
        case "lineup_porto":
        this.modal.create.service = LineupsPortosService;
        this.modal.create.params = {};
        this.modal.create.model = model;

        break;

        case "carga":
        if (!params && !params.carga_tipo_id) {
          this.$notify({
            type: "danger",
            message: "Please select the comm type",
            horizontalAlign: "center",
          });
          return false;
        }
        this.modal.create.service = CargasService;
        this.modal.create.params = { tipo_id: params.carga_tipo_id };
        break;
      }

      this.modal.create.type = type;
      this.modal.create.show = true;
    },
    handleModalForm(model) {
      let arrayOfFields = null;
      switch (this.modal.create.type) {
        case "agencia":
        this.list[this.rowIndex].agencia_id = model.id;
        arrayOfFields = "agencias";
        break;
        case "navio":
        arrayOfFields = "navios";

        this.rowIndex.navio_id = model.id;

        break;
        case "lineup_porto":
        this.rowIndex[this.modal.create.model] = model.id;
        arrayOfFields = "lineupPortos";
        break;
        case "afretador":
        this.rowIndex.afretador_id = model.id;
        arrayOfFields = "afretadores";
        break;
        case "carga":
        this.rowIndex.carga_id = model.id;
          // this.cargas[this.cargas.length] = model;
          let carga = this.carga.cargas.find((carga) => carga.id == model.id);
          if (carga) {
            carga.name = model.name;
          } else {
            this.carga.cargas.unshift(model);
          }
          break;
        }

        if (this.modal.create.type != "carga") {
          if (this.modal.create.id) {
            let field = this.$parent[arrayOfFields].find(
              (field) => field.id == model.id
              );
            this.$helper.set(field, model, Object.keys(field));
          } else {
            this.$parent[arrayOfFields].unshift(model);
          }
        }
        this.modal.create.show = false;
      },
      handleExcluir(updated) {
        this.$root.modal.excluir.isOpen = false;
        if (updated) this.$parent.listar();
      },
      marcarAtualizado(lineup, atualizar) {
        this.loading = true;
        Service.atualizar(lineup, atualizar).then((response) => {
          lineup.atualizado_data = response.data.atualizado_data;
          this.$notify({
            type: "success",
            message: "Atualizado com sucesso!",
            horizontalAlign: "center",
          });
          this.loading = false;
        });
      },
      listar() {
        this.loading = true;
        this.list = [];
        Service.get({
          ...this.search,
          with: [
          "carga",
          "cargaTipo",
          "terminal",
          "berco",
          "recebedores",
          "afretador",
          "embarcadores",
          "agencia",
          "navio",
          "importPorto",
          "exportPorto",
          'status'
          ],
        }).then((response) => {
          this.list = response.data;
          this.loading = false;
        });
      },
      getCargas(row) {
        if (row.carga_tipo_id) return this.cargas[row.carga_tipo_id].cargas;
        return this.cargas.reduce((list, c) => list.concat(c.cargas), []);
      },
      setCargaTipo(row) {
        if (!row.carga_tipo_id && row.carga_id) {
          const cargaTipo = this.cargas.find((tipo) => {
            if (!tipo || !tipo.cargas) return false;
            return tipo.cargas.some((c) => c.id == row.carga_id);
          });
          row.carga_tipo_id = cargaTipo.id;
        }
      },
    },
  };
</script>
<style>
.card .table.tabela-form td,
.card .table.tabela-form th {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.table td,
.table th {
  font-size: 0.6125rem;
  white-space: normal;
  padding: 0.1rem 1rem;
}

.tabela-form .multiselect,
.tabela-form .multiselect__input,
.tabela-form .multiselect__single {
  font-size: 0.875rem;
}

.tabela-form .multiselect {
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  min-height: 41px;
}

.tabela-form .multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 0px;
  padding-top: 0px;
}

.tabela-form .multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 5px 0 8px;
  border-radius: 5px;
  border: none;
  background: #fff;
  font-size: 14px;
}

.tabela-form .multiselect__input,
.tabela-form .multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 3px 0 0 0px;
  width: 100%;
  -webkit-transition: border 0.1s ease;
  transition: border 0.1s ease;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 0px;
  vertical-align: top;
}
.tabela-form .qty {
  width: 100px;
}

.tabela-form .multiselect__select {
  display: none;
}
</style>
