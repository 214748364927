<template>
  <div class="form-group"
  :class="[
  {'input-group': hasIcon},
  // {'has-danger': errors.length},
  {'focused': focused},
  {'has-label': label || $slots.label},

  ]">
  <slot name="label">
    <label v-if="label" class="form-control-label w-100" :class="labelClasses">
      {{label}}
      <span v-if="required">*</span>
    </label>
  </slot>


  <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend" @click="$emit('btn')">
    <span class="input-group-text">
      <slot name="addonLeft">
        <i :class="addonLeftIcon"></i>
      </slot>
    </span>
  </div>
  <slot v-bind="slotData">
    <select
    v-model="model"
    v-on="listeners"
    v-bind="$attrs"
    class="form-control"
    :class="[
    {'is-valid': valid === true},
    {'is-invalid': valid === false}, inputClasses]"
    aria-describedby="addon-right addon-left">
    <option v-if="placeholder" :value="null">{{placeholder}}</option>
    <option v-for="option in options" :value="option[optionsKey]">{{option[optionsValue]}}</option>
  </select>
</slot>
<div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
  <span class="input-group-text">
    <slot name="addonRight">
      <i :class="addonRightIcon"></i>
    </slot>
  </span>
</div>
<slot name="infoBlock"></slot>
<slot name="helpBlock">
  <!--   <div class="text-danger invalid-feedback" style="display: block;" :class="{'mt-2': hasIcon}" v-if="error">
      {{ error }}
    </div> -->
    <div class="text-danger invalid-feedback" style="display: block;" :class="{'mt-2': hasIcon}" v-for="erro in errors" v-if="errors">
      {{ erro }}
    </div>
  </slot>
</div>
</template>
<script>
  export default {
    inheritAttrs: false,
    name: "base-select",
    props: {
      required: {
        type: Boolean,
        description: "Whether input is required (adds an asterix *)"
      },
      // valid: {
      //   type: Boolean,
      //   description: "Whether is valid",
      //   default: undefined
      // },
      label: {
        type: String,
        description: "Input label (text before input)"
      },
      placeholder: {
        type: String,
        default:null,
        description: "default option"
      },
      // error: {
      //   type: String,
      //   description: "Input error (below input)"
      // },
      errors: {
        type: Array,
        default: ()=>[],
        description: "Input error (below input)"
      },
      labelClasses: {
        type: String,
        description: "Input label css classes"
      },
      inputClasses: {
        type: String,
        description: "Input css classes"
      },
      value: {
        type: [String, Number],
        description: "Input value"
      },
      options: {
        type: Array,
        description: "options value"
      },
      optionsKey: {
        type: String,
        default:'id',
        description: "key name"
      },
      optionsValue: {
        type: String,
        default:'name',
        description: "value name"
      },
      addonRightIcon: {
        type: String,
        description: "Addon right icon"
      },
      addonLeftIcon: {
        type: String,
        description: "Addont left icon"
      }
    },
    data() {
      return {
        focused: false,
        model:null
      };
    },
    created(){
      this.model = this.value;
    },
    watch:{
      value: {
    // the callback will be called immediately after the start of the observation
    immediate: true, 
    handler (val, oldVal) {
      this.model = val;
    }
  }
},
computed: {
  valid() {
    return this.errors.length
  },
  listeners() {
    return {
      ...this.$listeners,
      input: this.updateValue,
      focus: this.onFocus,
      blur: this.onBlur
    };
  },
  slotData() {
    return {
      focused: this.focused,
      ...this.listeners
    };
  },
  hasIcon() {
    const { addonRight, addonLeft } = this.$slots;
    return (
      addonRight !== undefined ||
      addonLeft !== undefined ||
      this.addonRightIcon !== undefined ||
      this.addonLeftIcon !== undefined
      );
  }
},
methods: {
  updateValue(evt) {
    let value = evt.target.value;
    this.model = value;
    this.$emit("input", value);
  },
  onFocus(value) {
    this.focused = true;
    this.$emit("focus", value);
  },
  onBlur(value) {
    this.focused = false;
    this.$emit("blur", value);
  }
}
};
</script>
<style>
</style>
