<template>
  <div class="card">
    <div class="card-header">
      <h1>Alterar senha</h1>
    </div>
    <div class="card-body">
      <form ref="password_form" @submit.prevent="handleChangePassword">
        <base-input
        v-model="password"
        type="password"
        name="new_password"
        autocomplete="on"
        class="input-group-alternative"
        addon-left-icon="ni ni-lock-circle-open"
        placeholder="Nova senha"
        />
        <validation-error :errors="errors.password" />

        <base-input
        v-model="password_confirmation"
        type="password"
        name="confirm_password"
        autocomplete="on"
        class="input-group-alternative"
        addon-left-icon="ni ni-lock-circle-open"
        placeholder="Confirmar senha"
        />
        <validation-error :errors="errors.password_confirmation" />
        <div class="my-4">
          <base-button
          type="button"
          class="btn btn-sm btn-primary"
          native-type="submit"
          >
          Alterar senha        
        </base-button>
      </div>
    </form>
  </div>
</div>
</template>
<script>
  import BaseInput from "@/components/BaseInput.vue";
  import BaseButton from "@/components/BaseButton.vue";
  import formMixin from "@/mixins/form-mixin";
  import ValidationError from "@/components/ValidationError.vue";

  export default {
    name: "UserPasswordCard",

    components: {
      BaseInput,
      BaseButton,
      ValidationError,
    },

    mixins: [formMixin],

    props: {
      user: Object,
    },

    data() {
      return {
        errors:{},
        password: null,
        password_confirmation: null,
      };
    },

    methods: {
      async handleChangePassword() {
        if(!this.password || !this.password_confirmation){
         this.$notify({
          type: "danger",
          message: "Preencha todos os campos!",
        });
         return false;
       }

       this.user.password = this.password;
       this.user.password_confirmation = this.password_confirmation;

       try {
        await this.$store.dispatch("profile/update", this.user);
        this.$refs["password_form"].reset();

        // this.resetApiValidation();
        this.errors ={};
        this.$notify({
          type: "success",
          message: "Senha alterada!.",
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Confira os dados!",
        });
        this.errors = error.response.data.errors;
        // this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
