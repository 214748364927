<template>
  <div>
    <base-header class="pb-6 pb-6 pt-5 pt-md-5" v-if="!isModal"> </base-header>

    <div :class="{ 'container-fluid mt-5': !isModal }">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Lineup</h3>
                </div>
                <div class="col-4 text-right">
                  <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <!-- porto -->
                <h6 class="heading-small text-muted mb-1">
                  Information of port
                </h6>

                <div class="row">
                  <div class="col-lg-3">
                    <base-select
                      alternative=""
                      label="Type"
                      :errors="errors.is_observacao"
                      :disabled="model.id"
                      :options="Service.opcaoTipo()"
                      required
                      input-classes="form-control-alternative"
                      @change="setType(model.is_observacao)"
                      v-model="model.is_observacao"
                    />
                  </div>
                  <div class="col-lg-3">
                    <base-select
                      alternative=""
                      label="Port"
                      :errors="errors.porto_id"
                      placeholder="select the port"
                      :options="portos"
                      required
                      input-classes="form-control-alternative"
                      @change="setPorto(true)"
                      v-model="model.porto_id"
                    />
                  </div>

                  <div class="col-lg-3">
                    <base-select
                      alternative=""
                      label="Terminal"
                      :errors="errors.terminal_id"
                      :disabled="!terminais.length"
                      placeholder="select the terminal"
                      :options="terminais"
                      @change="setTerminal(true)"
                      required
                      input-classes="form-control-alternative"
                      v-model="model.terminal_id"
                    />
                  </div>

                  <div class="col-lg-3">
                    <base-select
                      alternative=""
                      label="Berth"
                      :disabled="!bercos.length"
                      :errors="errors.berco_id"
                      :placeholder="
                        terminal.bercos && !terminal.bercos.length
                          ? 'This terminal does not have a cradle berth'
                          : 'Select the berth'
                      "
                      :options="bercos"
                      input-classes="form-control-alternative"
                      v-model="model.berco_id"
                    />
                  </div>
                </div>
                <!-- /porto -->

                <!-- vessel -->
                <h6 class="heading-small text-muted mb-2">
                  <span v-if="isLineup">Information of vessel</span>
                  <span v-else>Information of occurred</span>
                </h6>

                <div class="row">
                  <div class="col-lg-5" v-if="isLineup">
                    <multiple-select
                      alternative=""
                      label="Vessel"
                      :disabled="!navios.length"
                      :errors="errors.navio_id"
                      placeholder="Select the vessel"
                      :options="navios"
                      addonLeftIcon=" fa fa-plus"
                      input-classes="form-control"
                      v-model="model.navio_id"
                      :edit="true"
                      @btn="openModalCreate('navio')"
                      @edit="
                        openModalCreate('navio', null, null, model.navio_id)
                      "
                    />
                  </div>
                  <div class="col-lg-3" v-if="isLineup">
                    <multiple-select
                      alternative=""
                      label="Agency"
                      :disabled="!agencias.length"
                      :errors="errors.agencia_id"
                      placeholder="Select the agency"
                      :options="agencias"
                      addonLeftIcon=" fa fa-plus"
                      input-classes="form-control"
                      v-model="model.agencia_id"
                      ref="agencias"
                      @btn="openModalCreate('agencia')"
                      :edit="true"
                      @edit="
                        openModalCreate('agencia', null, null, model.agencia_id)
                      "
                    />
                  </div>

                  <div class="col-lg-6" v-if="!isLineup">
                    <base-input
                      alternative=""
                      label="Observation"
                      type="text"
                      :errors="errors.observacao"
                      input-classes="form-control-alternative"
                      v-model="model.observacao"
                    />
                  </div>
                  <div class="col-lg-4" v-if="isLineup">
                    <multiple-select
                      alternative=""
                      label="Charterer"
                      :errors="errors.afretador_id"
                      placeholder="Select the charterer"
                      :options="afretadores"
                      optionsValue="name"
                      addonLeftIcon=" fa fa-plus"
                      required
                      input-classes="form-control"
                      v-model="model.afretador_id"
                      @btn="openModalCreate('afretador')"
                      :edit="true"
                      @edit="
                        openModalCreate(
                          'afretador',
                          null,
                          null,
                          model.afretador_id
                        )
                      "
                    />
                  </div>
                  <div class="col-lg-3" v-if="isLineup">
                    <base-input-date
                      alternative=""
                      label="ETA"
                      :errors="errors.eta"
                      input-classes="form-control-alternative"
                      v-model="model.eta"
                    />
                  </div>

                  <div class="col-lg-3">
                    <base-input-date
                      alternative=""
                      label="ETB"
                      type="date"
                      :errors="errors.etb"
                      input-classes="form-control-alternative"
                      v-model="model.etb"
                    />
                  </div>

                  <div class="col-lg-3">
                    <base-input-date
                      alternative=""
                      label="ETS"
                      type="date"
                      :errors="errors.ets"
                      input-classes="form-control-alternative"
                      v-model="model.ets"
                    />
                  </div>

                   <div class="col-lg-3" v-if="isLineup">
                     <base-select
                      alternative=""
                      label="Status"
                      :errors="errors.lineup_status_id"
                      placeholder="select the status"
                      :options="status"
                      optionsValue="status"
                      required
                      input-classes="form-control-alternative"                      
                      v-model="model.lineup_status_id"
                    />
                  </div>

                  <!-- /vessel -->
                  <!-- /cargo -->
                  <!-- vessel -->
                </div>
                <h6 class="heading-small text-muted mb-2" v-if="isLineup">
                  <span
                    >Information of cargo
                    <base-button
                      type="primary"
                      class="float-right"
                      icon
                      size="sm"
                      @click="addCarga()"
                    >
                      <i class="fas fa-plus mr-2"></i>
                      <span class="btn-inner--text">Add carga</span>
                    </base-button></span
                  >
                </h6>

                <lineup_cargas
                  v-if="isLineup"
                  :model="model"
                  :errors="errors"
                  :cargasTipos="cargasTipos"
                  :trabalhadores="trabalhadores"
                  :lineupPortos="lineupPortos"
                  :ops="ops"
                  :is-modal="isModal"
                />

                <lineup_cargas
                  v-if="isLineup"
                  v-for="(lineupCarga, index) in model.lineups_cargas"
                  :key="index"
                  :model="lineupCarga"
                  :errorSufix="'lineups_cargas.' + index + '.'"
                  :cargasTipos="cargasTipos"
                  :trabalhadores="trabalhadores"
                  :lineupPortos="lineupPortos"
                  :ops="ops"
                  :errors="errors"
                  :allowDelete="true"
                  @delete="
                    () => {
                      model.lineups_cargas.splice(index, 1);
                    }
                  "
                  :is-modal="isModal"
                />
              </form>
            </template>

            <div class="mt-4">
              <button
                type="submit"
                class="btn base-button btn-dark float-left"
                v-if="!isModal"
                @click="$router.push('/lineups/listar')"
              >
                See listing
              </button>
              <button
                type="submit"
                class="btn base-button btn-primary float-right"
                :disabled="loading"
                @click="submit()"
              >
                Save lineup
              </button>
            </div>
          </card>
        </div>
      </div>
    </div>
    <modal :show="modal.create.show" @close="handleCloseModal">
      <template v-slot:header> Add new </template>
      <template>
        <generic-form
          v-if="modal.create.show"
          :service="modal.create.service"
          :type="modal.create.type"
          :id="modal.create.id"
          :params="modal.create.params"
          @success="handleModalForm"
        />
      </template>
    </modal>
  </div>
</template>
<script>
import Service from "@/services/Lineups";
import LineupsPortosService from "@/services/LineupsPortos";
import PortosService from "@/services/Portos";
import NaviosService from "@/services/Navios";
import TrabalhadoresService from "@/services/Trabalhadores";
import AgenciasService from "@/services/Agencias";
import AfretadoresService from "@/services/Afretadores";
import CargasTiposService from "@/services/CargasTipos";
import CargasService from "@/services/Cargas";
import GenericForm from "./../Generic/Form.vue";
import LineupCargas from "./Components/Cargas.vue";
import { Money } from "v-money";
export default {
  name: "lineups_form",
  components: {
    Money,
    lineup_cargas: LineupCargas,
    "generic-form": GenericForm,
  },
  created() {
    this.modelDefault = { ...this.model };
    Service.parametros({
      portos: true,
      navios: true,
      agencias: true,
      lineups_portos: true,
      afretadores: true,
      trabalhadores: true,
      cargas: true,
      ops: true,
      status:true
    }).then((response) => {
      this.portos = response.data.portos;
      this.navios = response.data.navios;
      this.lineupPortos = response.data.lineups_portos;
      this.agencias = response.data.agencias;
      this.afretadores = response.data.afretadores;
      this.trabalhadores = response.data.trabalhadores;
      this.cargasTipos = response.data.cargas_tipos;
      this.ops = response.data.ops;
      this.status = response.data.status;
      if (this.$route.params.id) {
        this.model.id = this.$route.params.id;
        this.find();
      }

      if (this.premodel && Object.keys(this.premodel).length) {
        this.$helper.set(this.model, this.premodel, Object.keys(this.model));
        console.log("premodal");
        // this.model.call_in_id = null;
        // this.model.terminal_id = this.premodel.terminal_id;
        this.setPorto(false);
        this.setTerminal(false);
        // this.setCargaTipo(false);
      }
      if (this.id) {
        //prop
        this.model.id = this.id;
        this.find();
      }
    });
  },
  props: {
    id: {
      default: null,
    },
    isModal: {
      default: 0,
    },
    premodel: {
      default: () => {},
    },
    success: {
      type: Function,
      default: null,
    },
    callInId: {
      default: null,
    },
  },
  data() {
    return {
      qtd: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: " (t)",
        precision: 2,
        masked: false,
      },
      modal: {
        create: {
          show: false,
          service: {},
          type: null,
          params: {},
          model: null,
          index: null,
        },
      },
      ops: [],
      errors: {},
      portos: [],
      navios: [],
      afretadores: [],
      terminais: [],
      agencias: [],
      status:[],
      bercos: [],
      terminal: {},
      cargas: [],
      cargasTipos: [],
      lineupPortos: [],
      trabalhadores: [],
      loading: false,
      Service,
      modelDefault: {},
      model: {
        is_observacao: 0,
        porto: {},
        terminal: {},
        embarcadores: [],
        recebedores: [],
        afretador_id: null,
        agencia_id: null,
        navio_id: null,
        porto_id: null,
        terminal_id: null,
        berco_id: null,
        carga_tipo_id: null,
        carga: {},
        carga_id: null,
        export_porto: null,
        import_porto: null,
        qty: 0,
        qty_bagged:0,
        qty_bulk:0,
        qty_unidade: "t",
        qty_import: 0,
        qty_export: 0,
        id: null,
        lineups_cargas: [],
      },
    };
  },
  computed: {
    isLineup() {
      return this.model.is_observacao == 1 ? false : true;
    },
    saldoImport() {
      let total = 0;

      if (this.model.recebedores) {
        total += this.model.recebedores.reduce(
          (acumulador, elemento) => (acumulador += parseFloat(elemento.qty)),
          0
        );
      }
      this.model.qty_import = total;
      return total;
    },
    saldoExport() {
      let total = 0;

      if (this.model.embarcadores) {
        total += this.model.embarcadores.reduce(
          (acumulador, elemento) => (acumulador += parseFloat(elemento.qty)),
          0
        );
      }
      this.model.qty_export = total;
      return total;
    },
  },
  methods: {
    addCarga() {
      this.Service.addCarga(this.model);
    },
    setPorto(clean) {
      let porto = this.portos.find((port) => port.id == this.model.porto_id);

      if (porto) {
        this.terminais = [...porto.terminais];
        this.model.porto = porto;
      } else {
        this.model.porto = {};
        this.terminais = [];
        this.model.terminal_id = null;
        this.model.terminal = {};
      }
      if (clean) {
        this.model.terminal = {};
        this.model.terminal_id = null;

        this.bercos = [];
        this.model.berco = null;
        this.model.berco_id = null;
      }
      this.$forceUpdate();
    },
    setTerminal(clean) {
      let terminal = this.terminais.find(
        (term) => term.id == this.model.terminal_id
      );
      if (terminal) {
        this.terminal = terminal;
        this.bercos = [...terminal.bercos];
      } else {
        this.terminal = {};
        this.bercos = [];
        this.model.berco_id = null;
      }
      if (clean) {
        this.model.berco_id = null;
      }
    },

    openModalCreate(type, model, index, id) {
      this.modal.create.id = id;
      switch (type) {
        case "agencia":
          this.modal.create.service = AgenciasService;
          this.modal.create.params = {};
          break;

        case "navio":
          this.modal.create.service = NaviosService;
          this.modal.create.params = {};
          break;

        case "trabalhador":
          this.modal.create.service = TrabalhadoresService;
          this.modal.create.params = {};
          this.modal.create.model = model;
          this.modal.create.index = index;
          break;

        case "afretador":
          this.modal.create.service = AfretadoresService;
          this.modal.create.params = {};

          break;
        case "lineup_porto":
          this.modal.create.service = LineupsPortosService;
          this.modal.create.params = {};
          this.modal.create.model = model;

          break;

        case "carga":
          if (!this.model.carga_tipo_id) {
            this.$notify({
              type: "danger",
              message: "Please select the cargo type",
              horizontalAlign: "center",
            });
            return false;
          }
          this.modal.create.service = CargasService;
          this.modal.create.params = { tipo_id: this.model.carga_tipo_id };
          break;
      }

      this.modal.create.type = type;
      this.modal.create.show = true;
    },
    handleModalForm(model) {
      let arrayOfFields = null;
      switch (this.modal.create.type) {
        case "agencia":
          this.model.agencia_id = model.id;
          arrayOfFields = "agencias";
          // this.agencias.push(model);
          break;
        case "navio":
          this.model.navio_id = model.id;
          arrayOfFields = "navios";

          break;
        case "trabalhador":
          this.model[this.modal.create.model][this.modal.create.index][
            "trabalhador_id"
          ] = model.id;
          arrayOfFields = "trabalhadores";
          // this.trabalhadores.push(model);
          break;
        case "lineup_porto":
          this.model[this.modal.create.model] = model.id;
          // this.lineupPortos.push(model);
          arrayOfFields = "lineupPortos";
          break;
        case "afretador":
          this.model.afretador_id = model.id;
          // this.afretadores.push(model);
          arrayOfFields = "afretadores";
          break;
        case "carga":
          this.model.carga_id = model.id;
          arrayOfFields = "cargas";
          // this.cargas.push(model);
          break;
      }

      if (this.modal.create.id) {
        let field = this[arrayOfFields].find((field) => field.id == model.id);
        this.$helper.set(field, model, Object.keys(field));
      } else {
        this[arrayOfFields].unshift(model);
      }

      this.modal.create.type = null;
      this.modal.create.show = false;
      if (!this.isModal)
        document.querySelector("body").classList.remove("modal-open");
      // this.$forceUpdate();
    },

    handleCloseModal() {
      this.modal.create.show = false;
      if (!this.isModal)
        document.querySelector("body").classList.remove("modal-open");
    },

    setType(type) {
      this.$helper.set(
        this.model,
        { ...this.modelDefault, is_observacao: type },
        Object.keys(this.model)
      );
    },
    resetModel() {
      this.$helper.set(this.model, this.modelDefault, Object.keys(this.model));
    },

    find() {
      this.loading = true;
      this.errors = {};
      Service.find(this.model.id, {
        with: ["carga", "embarcadores", "recebedores", "lineupsCargas"],
      })
        .then((response) => {
          this.model = response.data;
          this.setPorto(false);
          this.setTerminal(false);
          this.setCargaTipo(false);
          this.setUnidade();
          this.loading = false;
          if (this.callInId) {
            this.model.id = null;
            this.model.call_in_id = this.callInId;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      this.errors = {};
      Service.submit(this.model)
        .then((response) => {
          this.model = response.data;
          this.loading = false;
          this.$notify({
            type: "success",
            message: "Lineup saved successfully!",
            horizontalAlign: "center",
          });
          if (this.success) {
            this.success();
          }
          if (!this.isModal) {
            this.$router.back();
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            let errors = err.response.data.errors;
            this.errors = errors;
            this.$notify({
              type: "danger",
              message: "Please check the form",
              horizontalAlign: "center",
            });
          }
        });
    },
  },
};
</script>
<style>
button.input-padding {
  padding: 0.625rem 0.75rem;
}
.transparent {
  color: transparent;
}
</style>
